import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import SearchResult from '../components/mini/searchResult'
import SearchInput from '../components/mini/searchInput'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const search = location?.pathname
    .replace(/\/$/, '')
    .substring(location?.pathname.replace(/\/$/, '').lastIndexOf('/') + 1)
    .replace('.html', '')
    .replace(/-/g, ' ')
  const [searchQuery, setSearchQuery] = useState('')
    useEffect(() => {
      setSearchQuery(search)
    }, [location])
  return (
    <Layout
      location={location}
      title={siteTitle}
      language={{ slug: 'sv', code: 'SV' }}
      template={'Default'}
    >
      <Seo title="404: Not Found" />

      <section className="content text-center">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="  col-12 col-md-10 col-lg-8">
              <div className="row">
                <div className="col-12 subTitle">
                  <h4>Sidan hittades ej</h4>
                </div>
                <div className="col-12 title">
                  <h1>404 error</h1>
                </div>
              </div>
            </div>
            <div className=" col-12 col-md-10 col-lg-8">
              <div className="row">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-6 page-search-wrap">
                    <SearchInput
                      onSearchClick={(searchQuery) => {
                        setSearchQuery(searchQuery)
                      }}
                      initialQuery={searchQuery}
                    />
                  </div>
                </div>
                <div className="col-12 main-content columns-1">
                  <SearchResult searchQuery={searchQuery} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
